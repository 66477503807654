.flashScreen {
    position: fixed;
    width: 50%;
    height: 100%;
    inset: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    left: 50%;
    transition: all 0.5s ease-in-out;
    background: url(./../assets/images/loginbg.svg);
    background-position: right top;
    background-size: auto 100%;

    &.active {
        opacity: 1;
        left: 0%;
        width: 100%;
        background: #fff;

    }

    &:not(.active) {
        .color {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgb(255, 231, 19, 0.9);
            inset: 0;
        }
    }

    .logo {
        max-width: 370.19px;
        max-height: 408.31px;
        transform: scale(1, 1) rotate(0deg);
        animation: scale 3s ease-in;
        opacity: 1;

    }
}

@keyframes scale {

    0% {
        transform: scale(0.4, 0.4) rotate(360deg);
        opacity: 0.5;
    }

    50% {
        opacity: 0.8;
        transform: scale(0.5, 0.5);
    }

    100% {
        transform: scale(1, 1);
        opacity: 1;
    }

}

.loginWrapper {
    background-color: #fff !important;

    .formDiv {
        padding: 1rem;
        flex: 0 0 50%;

        form {
            max-width: 375px;
            margin: auto;

            h1 {
                font-size: 24px;
                color: var(--green);
                font-weight: 600;
            }

            button {
                height: 65.56px;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
}