.react-datepicker__tab-loop {
    position: relative;
    .react-datepicker-popper {
        top: auto !important;
        transform: unset !important;
        width: 100%;
        > div {
            > div.react-datepicker {
                font-family: "Poppins", sans-serif !important;
                font-size: 14px;
                font-weight: 500;
                background-color: #fff;
                color: var(--grayBlack) !important;
                border: 1px solid var(--borderColor);
                border-radius: 0.3rem;
                display: inline-block;
                position: relative;
                width: 100%;
                box-shadow: 10px 10px 30px #c4ccd6 !important;
                > button {
                    top: 21px;
                }
                .react-datepicker__triangle {
                    display: none !important;
                }
                .react-datepicker__header {
                    background-color: #fff;
                    border-bottom: 0;
                    margin-top: 10px;
                    .react-datepicker__current-month {
                        color: #7d828d;
                        font-weight: 500;
                    }
                    > div.react-datepicker__day-names {
                        margin-top: 20px;
                        .react-datepicker__day-name {
                            width: 39px;
                            color: var(--grayBlack);
                        }
                    }
                }

                .react-datepicker__month-container {
                    width: 100%;
                    > div.react-datepicker__month {
                        > .react-datepicker__week {
                            > .react-datepicker__day {
                                width: 39px;
                                height: 39px;
                                line-height: 40px;
                                color: var(--grayBlack);
                                &:hover {
                                    border-radius: 50%;
                                }
                            }
                            > .react-datepicker__day--keyboard-selected , .react-datepicker__day--selected {
                                border-radius: 50%;
                                background-color: var(--green);
                                color: #fff;
                            }
                        }
                    }
                }
                .react-datepicker__navigation{
                    height: 10px !important;
                }
            }
        }
    }
}
